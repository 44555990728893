body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body{
  height: 100%;
  height: 100vh;
  overflow: hidden;
}
#root
{
  height: 100%;
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.sidebar {
  max-width: 240px;
  height: 100vh;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-item-icon {
  margin-right: 6px;
}

.sidebar-item-text {
  width: 100%;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
  color: #09bb12;
  font-weight: bold;
}

.MuiOutlinedInput-input{	
  padding: 9.5px 14px!important;	
 }


 
/* scroll start*/
::-webkit-scrollbar {
  width: 4px;
  background:#d8d8d8;
}


/* Track */

::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-border-radius: 4px
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: #858585;
  border-radius: 4px;
}


  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
      background: #585858;
  }


/* scroll*/
span.MuiTouchRipple-root
{
  display: none !important;
}

div.MuiListItem-gutters
{
padding: 0px 5px !important;
background: transparent !important;
}
div.MuiListItem-gutters .manu-item
{
  float: left;
  width: 98%;
  /*ellipsis*/
  white-space: initial; 
  overflow: hidden;
  /* text-overflow: ellipsis;  */
  /*ellipsis end*/
}
ul .manu-item p
{
  padding: 8px 10px !important;
  /*ellipsis*/
  white-space: initial; 
  overflow: hidden;
  /* text-overflow: ellipsis;  */
  /*ellipsis end*/

}

ul ul .manu-item p
{
  padding: 8px 12px !important;
  color: #2a6496;
  
}

ul ul ul .manu-item p
{
  padding: 8px 14px !important;
  color: #428bca;
}
ul ul ul ul .manu-item p
{
  padding: 8px 16px !important;
  color: #216b82;
}

div.MuiListItem-gutters svg
    {
        float:right;

    }


@media only screen and (max-width:767px) and (min-width: 320px){

  html, body
  {
    overflow: visible !important;
  }
.makeStyles-container,
.MuiGrid-container
{
  width: 100% !important;
    padding: 20px 5px;
    margin-top: 40px;
    min-height: 0;
    margin-left: 0px !important;
}

.makeStyles-leftContent-50{
  margin-right:0 !important;
}
.makeStyles-leftContent-50, .makeStyles-rightContent-51{
  width:100% !important;
}
}

.MuiAccordionDetails-root, .MuiAccordionSummary-root{
  display:block !important;
  padding:0.1rem !important;
}

.MuiAccordionSummary-content{
  margin:0 !important;
}

.MuiAccordionSummary-root{
  min-height:10px !important;
}

.MuiAccordion-root.Mui-expanded{
  margin: 0 !important;
}