.pagination {
  margin: 15px 0 auto;
  display: flex;
  list-style: none;
  outline: none;   
  height: 65px;   
  width: 100%;   
  flex-flow: row wrap;
}
.pagination > .active > span{
  background:linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%) ;
  border-color: linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%);
  color: #fff;
  text-decoration:none;
}
.pagination > li > span{
  border: 1px solid linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%) ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  text-decoration:none;
  color:linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%);
}
.pagination > .active > span, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus, .pagination > .active > span:focus{
  background:linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%) ;
  border-color: linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%);
  outline: none;
  color:linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%);
}
.pagination > li > span, .pagination > li > span, .pagination .page-link{
  color: linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%)
}
.pagination > li:first-child > span, .pagination > li:first-child > span, .pagination > li:last-child > span, .pagination > li:last-child > span{
  border-radius: unset
}
.pagination .page-link{
  color: linear-gradient(0deg, rgba(43,44,44,0.9) 0%, rgba(43,44,44,1) 35%, rgba(43,44,44,0.9) 100%)
}